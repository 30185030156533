import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CallbackComponent } from 'redux-oidc';
import { push } from 'connected-react-router';
import userManager from './../userManager';
import { userFound } from 'redux-oidc';

class CallbackPage extends Component {

  successCallback = (user) => {
    // get the user's previous location, passed during signinRedirect()
    console.log(["CALLBACK", user.state]);
    this.props.dispatch(userFound(user));

    var redirectPath = user.state.path;
    this.props.dispatch(push(redirectPath));

    if (user.state.openUrl) {
      window.open(user.state.openUrl, '_blank');      
    }
  }

  errorCallback = (error) => {
    console.log(error);
    this.props.dispatch(push('/'));
  }

  render() {
    return (
      <CallbackComponent
        userManager={userManager}
        successCallback={this.successCallback}
        errorCallback={this.errorCallback}
      >
        <div>Loading...</div>
      </CallbackComponent>
    );
  }
}

export default connect()(CallbackPage);