
import './css/site.scss';
import 'semantic-ui-css/semantic.min.css'
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { Route } from 'react-router' // react-router v4
import { ConnectedRouter } from 'connected-react-router'
import configureStore, { history } from './configureStore'
import * as serviceWorker from './serviceWorker';
import RoutesModule from './routes';

const store = configureStore(window.initialReduxState);
var routes = <Route path="*" render={(props) => <RoutesModule { ...props } />} />;

ReactDOM.render(
    <Provider store={store}>
       <ConnectedRouter history={ history } children={ routes } />
    </Provider>,
    document.getElementById('root')
  )


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();