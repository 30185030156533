var claimTypes = {
	actor: 'http://schemas.xmlsoap.org/ws/2009/09/identity/claims/actor',
	anonymous: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/anonymous',
	authentication: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authenticated',
	authorizationDecision: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authorizationdecision',
	country: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country',
	dateOfBirth	: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/dateofbirth',
	denyOnlySid	: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/denyonlysid',
	dns	: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/dns',
	email: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/email',
	gender: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/gender',
	givenName: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname',
	hash: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/hash',
	homePhone: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/homephone',
	locality: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/locality',
	mobilePhone: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/mobilephone',
	name: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name',
	nameIdentifier: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier',
	otherPhone: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/otherphone',
	postalCode: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/postalcode',
    rsa: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/rsa',
    role: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role',
	sid: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid',
	spn: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/spn',
	stateOrProvince: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/stateorprovince',
	streetAddress: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/streetaddress',
	surname: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname',
	system: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/system',
	thumbprint: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/thumbprint',
	upn: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/upn',
	uri: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/uri',
	webpage: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/webpage',
	x500DistinguishedName: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/x500distinguishedname'
}

var inversClaimTypes = {
	'http://schemas.xmlsoap.org/ws/2009/09/identity/claims/actor' : 'actor',
	'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/anonymous' : 'anonymous',
	'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authenticated' : 'authentication',
	'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authorizationdecision' : 'authorizationDecision',
	'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country' : 'country',
	'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/dateofbirth' : 'dateOfBirth',
	'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/denyonlysid' : 'denyOnlySid',
	'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/dns' : 'dns',
	'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/email' : 'email',
	'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/gender' : 'gender',
    'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname' : 'givenName',
    'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/hash' : 'hash',
	'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/homephone' : 'homePhone',
	'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/locality' : 'locality',
	'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/mobilephone' : 'mobilePhone',
	'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name' : 'name',
	'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier' : 'nameIdentifier',
	'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/otherphone' : 'otherPhone',
	'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/postalcode' : 'postalCode',
    'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/rsa' : 'rsa',
    'http://schemas.microsoft.com/ws/2008/06/identity/claims/role' : 'role',
	'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid' : 'sid',
	'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/spn' : 'spn',
	'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/stateorprovince' : 'stateOrProvince',
	'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/streetaddress' : 'streetAddress',
	'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname' : 'surname',
    'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/system' : 'system',
	'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/thumbprint' : 'thumbprint',
	'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/upn' : 'upn',
	'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/uri' : 'uri',
	'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/webpage' : 'webpage',
	'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/x500distinguishedname' : 'x500DistinguishedName'
}

export {
    claimTypes,
    inversClaimTypes
}