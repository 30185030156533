import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Header, Segment } from 'semantic-ui-react';

class Home extends Component {
    state = {
        result: null
    }

    render() {
        return (
            <Container text>
                <Header
                    as='h1'
                    content='24Readings administration'
                    style={{ fontSize: '4em', fontWeight: 'normal', marginBottom: 0, marginTop: '3em', }}
                />

                {!this.props.user && (
                    <Header
                        as='h2'
                        content='Log in om gebruik te maken van admin'
                        style={{ fontSize: '1.7em', fontWeight: 'normal', marginTop: '1.5em', }}
                    />
                )}

                {this.state.result && <Segment>
                    {this.state.result}
                </Segment>}

            </Container>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.oidc.user,
    };
}

export default connect(mapStateToProps, null)(Home);