import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import CallbackPage from './components/callbackPage';

import Layout from './components/layout';
import UserInfo from './components/userInfo';
import UserList from './components/userList';
import Home from './components/home';
import Counter from './components/counter';

class RoutesModule extends Component {
    render() {
        // wait for user to be loaded, and location is known
        if (this.props.isLoadingUser || !this.props.location) {
            return null;
        }

        // if location is callback page, return only CallbackPage route to allow signin process
        if (this.props.location.pathname === '/callback') {
            return <Route path='/callback' component={CallbackPage} />
        }
        
        return (
            <Switch>
                <Layout user={this.props.user}>
                    <Route exact path='/' component={Home} />
                    <Route path='/counter' component={Counter} />
                    <Route path='/users' component={UserList} />
                    <Route path='/user/:userId' component={UserInfo} />                    
                </Layout>
            </Switch>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.oidc.user,
        isLoadingUser: state.oidc.isLoadingUser,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RoutesModule);