import { createUserManager } from 'redux-oidc';


console.log(process);

const userManagerConfig = {
  client_id: process.env.REACT_APP_client_id,
  redirect_uri: process.env.REACT_APP_redirect_uri,
  response_type: process.env.REACT_APP_response_type,
  scope: process.env.REACT_APP_scope,
  authority: process.env.REACT_APP_authority,
  silent_redirect_uri: process.env.REACT_APP_silent_redirect_uri,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  monitorSession: true
};

export default createUserManager(userManagerConfig);