import React, { Component } from 'react';
import userManager from './../userManager';

import { Button, Container, Menu, Segment, } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom';

export default class Layout extends Component {
  logout = (event) => {
    event.preventDefault();
    userManager.signoutRedirect();
    userManager.removeUser();
  }

  login() {
    // pass the current path to redirect to the correct page after successfull login
    userManager.signinRedirect({
      data: { path: window.location.pathname }
    });
  }

  stopImpersonating() {
    // pass the current path to redirect to the correct page after successfull login
    console.log(userManager);
    userManager.signinRedirect({
      data: { path: window.location.pathname },
      acr_values: "impersonatedUserId:STOP"
    });
  }

  renderPublicMenu() {
    return (
      <Menu pointing secondary size='large'>
        <Container>
          <Menu.Item as={NavLink} to="/" exact>Home</Menu.Item>
          <Menu.Item position='right' >
            <Button as='a' onClick={() => this.login()}>Log in</Button>
          </Menu.Item>
        </Container>
      </Menu>);
  }

  usersActive(match, location) {
    if (match) return true;
    return location.pathname.startsWith('/user/');
  }

  renderAuthMenu() {
    let impersonating = (this.props.user.profile.Impersonating || ";").split(";", 2)[1];

    return (
      <Menu pointing secondary size='large'>
        <Container>
          <Menu.Item as={NavLink} to="/" exact>Home</Menu.Item>
          <Menu.Item as={NavLink} isActive={this.usersActive} to="/users">Users</Menu.Item>
          {impersonating && <Menu.Item position='right'>
            <Button as='a' onClick={this.stopImpersonating}>Log out as: {decodeURI(impersonating)}</Button>            
          </Menu.Item>}
          <Menu.Item position='right' >          
            <Button as='a' onClick={(event) => this.logout(event)}>Log out</Button>
          </Menu.Item>
        </Container>
      </Menu>);
  }

  render() {
    let isConnected = !!this.props.user;
    return (
     <Segment vertical>
        {isConnected ? this.renderAuthMenu() : this.renderPublicMenu()}
        <Container>
          {this.props.children}
        </Container>
      </Segment >
    )
  }
}