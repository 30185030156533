import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { List, Image, Header } from 'semantic-ui-react';
import axios from 'axios';

class UserList extends Component {
    state = {
        users: []
    }

    componentDidMount() {
        //get users list
        //todo put in services folder
        this._isMounted = true;
        if (this.props.user) {
            if (!axios.defaults.headers.common['Authorization']) {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.props.user.access_token;
            }

            axios.get(`${process.env.REACT_APP_IDP_Api}user`)
                .then((response) => {
                    if (this._isMounted) {
                        this.setState({ users: response.data });
                    }
                })
                .catch(error => {
                    if (this._isMounted) {
                        this.setState({ users: [] });
                    }
                });
        }
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    renderRow(u) {
        return (
            <List.Item key={u.userId}>
                <Image avatar src={u.profileImage} />
                <List.Content>
                    <List.Header><Link to={'/user/' + u.userId}>{u.displayName}</Link></List.Header>
                    <List.Description>
                        {u.emailConfirmed ? (<span className="ok">&#x2713;</span>) : (<span className="nok">&#x2717;</span>)} {u.emailAddress}
                    </List.Description>
                </List.Content>
            </List.Item>
        )
    }

    render() {
        return (
            <React.Fragment>
                <Header as='h3' color="grey">Gebruikers</Header>
                <List>{this.state.users.map((u) => this.renderRow(u))}</List>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.oidc.user,
    };
}

export default connect(mapStateToProps, null)(UserList);