export const actionCreators = {
    increment: () => { return { type: 'INCREMENT_COUNT' } },
    decrement: () => { return { type: 'DECREMENT_COUNT' } }
};

export const reducer = (state, action) => {
    //console.log(action);
    switch (action.type) {
        case 'INCREMENT_COUNT':
            return { count: state.count + 1 };
        case 'DECREMENT_COUNT':
            return { count: state.count - 1 };        
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            return state || { count: 0 };
    }
    
};