import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Grid, Image, List, Card, Accordion, Icon, Divider, Container, Message, Form, Input, Select } from 'semantic-ui-react';
import axios from 'axios';
import { inversClaimTypes } from '../lib/claimTypes';
import userManager from './../userManager';
import moment from 'moment';
import update from 'immutability-helper';

class UserInfo extends Component {
  supportedClaims = [
    { key: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname', text: 'Voornaam' },
    { key: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname', text: 'Achternaam' },
    { key: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/streetaddress', text: 'Straatnaam' },
    { key: 'adressnumber', text: 'Huisnummer' },
    { key: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/postalcode', text: 'Postcode' },
    { key: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/locality', text: 'Woonplaats' },
    { key: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/mobilephone', text: 'Telefoon' },
    { key: 'VAT-number', text: 'BTW/ BSN nummer' },
    { key: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/dateofbirth', text: 'Geboortedatum' },

    { key: 'profile-progress', text: 'Voortgang' },

    { key: 'companyname', text: 'Bedrijfsnaam' },
    { key: 'ProfileImage', text: 'Profile Image' },

    { text: "KVK nummer", key: "KVK-number" },
    { text: "IBAN", key: "IBAN" },
    { text: "BIC", key: "BIC" },
    
    { key: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role', text: 'Role' },
  ];

  constructor(props) {
    super(props);
    this.state = {
      activeIndices: {},
      user: {},
      dasboard: {},
      usr: {},
      newClaim: {
        value: "",
        type: ""
      }
    }

    this.supportedClaims.forEach(element => {
      element.value = element.key
    });

    this.handleUserFormChange = this.handleUserFormChange.bind(this);
    this.handleUserFormSubmit = this.handleUserFormSubmit.bind(this);
  }

  componentDidMount() {
    let userId = this.props.match.params.userId;

    //get users list
    //todo put in services folder
    this._isMounted = true;
    if (this.props.user) {
      if (!axios.defaults.headers.common['Authorization']) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.props.user.access_token;
      }

      axios.get(`${process.env.REACT_APP_IDP_Api}user/${userId}`)
        .then((response) => {
          if (this._isMounted) {
            this.setState({
              activeIndex: -1,
              user: response.data,
              usr: update(response.data, {})
            });
          }
        })
        .catch(error => {
          if (this._isMounted) {
            this.setState({
              activeIndex: -1,
              user: {}
            });
          }
        });

      axios.get(`${process.env.REACT_APP_ADMIN_Api}dashboard/${userId}`)
        .then((response) => {
          if (this._isMounted) {
            this.setState({ dasboard: response.data });
          }
        })
        .catch(error => {
          if (this._isMounted) {
            this.setState({ dasboard: {} });
          }
        });
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  getKeys(obj) {
    let result = [];
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        result.push(key);
      }
    }
    return result;
  }

  handleAccordionClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndices } = this.state

    activeIndices[index] = !activeIndices[index];
    this.setState({ activeIndices: activeIndices })
  }

  mailAction = (act) => {
    axios.post(`${process.env.REACT_APP_IDP_Api}user/${this.props.match.params.userId}/mailAction`, { action: act })
  }

  impersonateUser = () => {
    userManager.signinRedirect({
      data: {
        path: window.location.pathname,
        openUrl: "https://my.claxe.com"
      },
      acr_values: `impersonatedUserId:${this.props.match.params.userId};${encodeURI(this.state.user.displayName)}`
    });
  }

  handleUserFormChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      saveUserMessage: null,
      saveUserErrorMessage: null,
      usr: update(this.state.usr, {
        [name]: { $set: value }
      })
    });
  }

  handleUserFormSubmit(event) {
    //set the user object and send the user object to the api
    var newUser = update(this.state.usr, {})

    axios.post(`${process.env.REACT_APP_IDP_Api}user/${this.props.match.params.userId}`, newUser)
      .then(() => {
        if (this._isMounted) {
          console.log("SUCCES");
          this.setState({
            user: newUser,
            saveUserMessage: "Succesvol opgeslagen",
            saveUserErrorMessage: null
          });
        }
      })
      .catch(() => {
        console.log("NO SUCCESS");
        this.setState({
          saveUserMessage: null,
          saveUserErrorMessage: "Waarschijnlijk bestaat dit gebruikersnaam of email adres al"
        });
      });

    event.preventDefault();
  }

  handleClaimChange(event, claimKey, i) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    this.setState({
      saveUserMessage: null,
      saveUserErrorMessage: null,
      usr: update(this.state.usr, {
        claims: {
          [claimKey]: {
            [i]: {
              $set: value
            }
          }
        }
      })
    });
  }

  handleDeleteClaim(event, claimKey, i) {
    this.setState({
      saveUserMessage: null,
      saveUserErrorMessage: null,
      usr: update(this.state.usr, {
        claims: {
          [claimKey]: { $splice: [[i, 1]] }
        }
      })
    });
    event.preventDefault();
  }

  newClaimChanged(event, key) {
    let value = null;
    if (key === "type") {
      value = event.value;
    } else {
      let target = event.target;
      value = target.type === 'checkbox' ? target.checked : target.value;
    }

    let newClaim = update(this.state.newClaim, {
      [key]: { $set: value }
    });

    this.setState({
      newClaim: newClaim
    });
  }

  addClaim(event) {
    let usr = this.state.usr;

    let newStateObject = {
      newClaim: {
        value: "",
        type: ""
      }
    };

    if (this.state.newClaim.type && this.state.newClaim.value) {
      let t = this.state.newClaim.type;
      let v = this.state.newClaim.value;
      //check existing claims
      if (usr.claims[t]) {
        if (usr.claims[t].filter(c => c === v).length === 0) {
          newStateObject.usr = update(this.state.usr, { claims: { [t]: { $push: [v] } } });
        }
      } else {
        newStateObject.usr = update(this.state.usr, { claims: { [t]: { $set: [v] } } });
      }
    }

    this.setState(newStateObject);
    event.preventDefault();
  }

  renderEditClaimsPart() {
    let usr = this.state.usr;

    return (
      <Form size='tiny' onSubmit={this.handleUserFormSubmit}>
        <Form.Field label='User Name' name="userName" value={usr.userName} onChange={this.handleUserFormChange} control='input' />
        <Form.Field label='Email adres' name="email" value={usr.email} onChange={this.handleUserFormChange} control='input' />

        {this.getKeys(usr.claims).filter(k => usr.claims[k].length > 0).map((claimKey, i) => {
          return (
            <Form.Field key={`ClaimFields-${i}`}>
              <label>{inversClaimTypes[claimKey] ? inversClaimTypes[claimKey] : claimKey}</label>
              {usr.claims[claimKey].map((value, j) => {
                return (
                  <Input
                    key={`inpClaim-${i}-${j}`}
                    action={{ color: 'red', icon: 'trash alternate outline', onClick: (event) => this.handleDeleteClaim(event, claimKey, j) }}
                    value={value}
                    onChange={(event) => this.handleClaimChange(event, claimKey, j)}
                  />)
              })}
            </Form.Field>
          );
        })}

        <Form.Field>
          <label>Claim toevoegen</label>
          <Input type='text' action>
            <input value={this.state.newClaim.value} onChange={(event) => this.newClaimChanged(event, 'value')} />
            <Select value={this.state.newClaim.type} options={this.supportedClaims} onChange={(event, opt) => this.newClaimChanged(opt, 'type')} />
            <Button onClick={(event) => this.addClaim(event)} color='teal'>+ Claim</Button>
          </Input>
        </Form.Field>

        {this.state.saveUserMessage && <Message positive>
          <Message.Header>Geslaagd</Message.Header>
          <p>{this.state.saveUserMessage}</p>
        </Message>}

        {this.state.saveUserErrorMessage && <Message negative>
          <Message.Header>Er ging iets mis</Message.Header>
          <p>{this.state.saveUserErrorMessage}</p>
        </Message>}

        <Form.Button content='Opslaan' />
      </Form>
    );
  }
 

  render() {
    if (this.props.user) {
      let user = this.state.user;
      if (user.userName) {
        return (<React.Fragment>
          <Grid divided>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={8} computer={8}>
                <Image size='tiny' circular src={user.claims.ProfileImage[0]} floated="left" />

                <List>
                  <List.Item>
                    <List.Header as='h2'>{user.displayName}</List.Header>
                  </List.Item>

                  <List.Item>
                    <List.Header>Gebruikersnaam</List.Header>
                    {user.userName}
                  </List.Item>

                  <List.Item>
                    <List.Header>E-mail</List.Header>
                    {user.email}
                  </List.Item>

                  <List.Item>
                    <List.Header>E-mail bevestigd</List.Header>
                    {user.emailConfirmed ? (<span className="ok">Ja</span>) : (<span className="nok">Nee</span>)}
                  </List.Item>

                  <List.Item>
                    <List.Header>Claims</List.Header>
                    <List>
                      {this.getKeys(user.claims).map((claimKey, i) => {
                        return (
                          <List.Item key={i}>
                            <List.Header as='b'>{inversClaimTypes[claimKey] ? inversClaimTypes[claimKey] : claimKey}</List.Header>
                            <List>
                              {user.claims[claimKey].map((value, j) => {
                                return (
                                  <List.Item key={`${i}-${j}`}>{value}</List.Item>
                                )
                              })}
                            </List>
                          </List.Item>
                        );
                      })}
                    </List>
                  </List.Item>
                </List>

              </Grid.Column>
              <Grid.Column mobile={16} tablet={8} computer={8}>

                <Card.Group>
                  <Card fluid color='red' >
                    <Card.Content header='Acties' />
                    <Card.Content>
                      <List>
                        <List.Item>
                          <List.Icon name='mail' />
                          <List.Content>
                            <span className="link" onClick={() => this.mailAction("Activation")}>Email activatie opnieuw sturen</span>
                          </List.Content>
                        </List.Item>
                        <List.Item>
                          <List.Icon name='mail' />
                          <List.Content>
                            <span className="link" onClick={() => this.mailAction("ResetPassword")}>Wachtwoord reset sturen</span>
                          </List.Content>
                        </List.Item>
                      </List>
                    </Card.Content>
                  </Card>

                  <Card fluid color='yellow'>
                    <Card.Content header='Bewerk' />
                    <Card.Content>
                      {this.renderEditClaimsPart()}
                    </Card.Content>
                  </Card>
                  
                </Card.Group>

              </Grid.Column>
            </Grid.Row>
          </Grid>
        </React.Fragment>);

        //<!-- {this.state.users.map((u) => this.renderRow(u))} -->

      } else {
        return <div>Loading user information</div>
      }
    } else {
      return <div>Please log in to display user info</div>
    }
  } renderAllowedWebsite
}

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

export default connect(mapStateToProps, null)(UserInfo);